const STAGING = "prod"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "d50p7n7wxiztpb0qm1cb7s1ae1dl3ih3iinh76cbt0qoqqpc",
  dev: "d50p7n7wxiztpb0qm1cb7s1ae1dl3ih3iinh76cbt0qoqqpc",
  prod: "jdr9qz99umtppqojpqa3y0mhwsdy3cbi6wzgfwpr1x0cebbx",
  preview: "lghgym85xf9612idq509udk726gbe3dtvbjpxk3ibxdc0skx",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}