// Libraries
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  // TextField,
  Tooltip,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { FaRegEdit } from 'react-icons/fa';
import { BiSolidSave } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import domToImage from 'dom-to-image';
import { Editor } from '@tinymce/tinymce-react';
import {
  // PDFDownloadLink,
  pdf,
  Document,
  Page,
  Image,
  // StyleSheet,
  // Text,
  // View,
  // Font,
} from '@react-pdf/renderer';
import axios from 'axios';
import { saveAs } from 'file-saver';

// Components
import './style.css';
import MainCard from 'ui-component/cards/MainCard';
import Popup from 'components/Popup';
import { API } from 'api/API';
import Axios from 'api/Axios';
import Loading from 'components/Loading';
import useScriptRef from 'hooks/useScriptRef';
import Message from 'components/Snackbar/Snackbar';
import images from 'assets/images/Images';
import { Keys } from 'api/Keys';
import {
  excludedTemplates,
  getContentExpand,
  getContentHeight,
  getHeightByTemplateName,
  getOriginalHeightByTemplateName,
  templates,
  templatesToUsePdfBlob,
  Template_heights,
} from '../WallboardHeights';
import { fontStyles, getFontStyles } from 'constants/fonts';

// const modules = {
//   toolbar: [
//     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//     [{ font: [] }],
//     // [{ size: ["10px", "12px", "14px", "16px", "18px", "20px"] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { list: "-1" },
//       { indent: "+1" },
//     ],
//     [{ align: [] }],
//     ["clean"],
//     ["link", "image", "video"],
//     [{ color: [] }], // Add the 'color' format to the toolbar
//     ["space"],
//   ],
// };

const ShowVersionsData = ({ ...others }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);
  const pdfContainerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [data, setData] = useState({
    c5: [],
    c6: [],
    status: '',
    color: '',
    wallboard_name: '',
    parent_template_name: '',
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [prevalue, setPrevalue] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });
  const [edit, setEdit] = useState({
    c5: false,
    showC5: false,
    show: false,
    approveDisabled: false,
  });
  const [EditorButtons, setEditorButtons] = useState({
    ShowMenu: false,
    CharacterCount: undefined,
    oldContent: '',
    Preview_popup: '',
    CloseDialog: false,
  });

  const [isformEditted, setFormEditted] = useState({ id: '', status: false });

  const [value, setValue] = useState({
    body: '',
    title: '',
    author_name: '',
    bodyC6: '',
    titleC6: '',
    author_nameC6: '',
    parent_template_name: '',
  });

  const [approved, setApproved] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const useStyles = makeStyles(theme => ({
    p: {
      fontFamily: 'AGaramond-Regular',
      lineHeight: 1.2,
      fontSize: '15px',
      textAlign: 'justify',
      wordSpacing: '0.4px',
      letterSpacing: '-0.5px',
      // hyphens: "auto",
      wordBreak: 'break-all',
    },
    h3: {
      fontFamily: 'Korolev',
      fontSize: '20px',
      lineHeight: 1.2,
      textTransform: 'capitalize !important',
      margin: 0,
      textAlign: 'justify',
      letterSpacing: '-0.5px',
    },
    img: {
      width: '100%',
    },
  }));

  const classes = useStyles();

  const removeHtmlTags = htmlString => {
    // Use a browser-based DOM parser to handle HTML parsing
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');

    // Initialize an empty string to store the final result
    let result = '';

    // Recursive function to process each node and its children
    const processNode = node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const nodeName = node.nodeName.toLowerCase();

        if (nodeName === 'b' || nodeName === 'strong') {
          result += `<b>${node.textContent}</b>`;
        } else if (nodeName === 'i' || nodeName === 'em') {
          result += `<${nodeName}>`;
          node.childNodes.forEach(child => processNode(child));
          result += `</${nodeName}>`;
        } else if (nodeName === 'a') {
          node.childNodes.forEach(child => processNode(child));
        } else {
          result += node.outerHTML;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        result += node.textContent;
      }
    };

    // Iterate over each child node of the parsed HTML document's body
    doc.body.childNodes.forEach(node => processNode(node));

    // Return the final result string with the processed HTML content
    return result;
  };

  // Get All Wallboards API
  const getData = async gettingID => {
    const id = params.id;
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Get_Single_Version, {
        id: gettingID || id,
      });
      if (response.status === 200) {
        // console.log(response.data, "test response");
        setData({
          c5: response?.data?.versiondetail?.c5,
          c6: response?.data?.versiondetail?.c6,
          status: response.data.approval_status,
          color: response?.data?.template_theme_name,
          wallboard_name: response?.data?.wallboard_name,
          parent_template_name: response?.data?.parent_template_name,
        });

        const data = response.data.versiondetail;
        const databody1 = data?.c5?.body.replace(/&nbsp;/g, ' ');
        const body1 =
          response?.data?.is_edit === true ? data?.c5?.body : removeHtmlTags(data?.c5?.body);
        // const body1 = data?.c5?.body;
        const body2 =
          response?.data?.is_edit === true ? data?.c6?.body : removeHtmlTags(data?.c6?.body);
        const values = {
          author_name: data?.c5.author_name || '',
          author_nameC6: data?.c6?.author_name || '',
          // body: data?.c5?.body || "",
          body: body1 || '',
          bodyC6: body2 || '',
          // bodyC6: data?.c6?.body || "",
          title:
            response.data.is_edit === true ? data.c5.title : `<h3>${data?.c5?.title}</h3>` || '',
          titleC6:
            response.data.is_edit === true ? data?.c6?.title : `<h3>${data?.c6?.title}</h3>` || '',
          parent_template_name: response?.data?.parent_template_name,
          // title: data?.c5?.title || "",
          // titleC6: data?.c6?.title || "",
        };
        setValue(values);
        setPrevalue(values);
        setIsLoading(false);
        return values;
      }
    } catch (err) {
      console.log(err, 'Error while Getting Data');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response.data.error || err.message,
        severity: 'error',
      });
      setTimeout(() => {
        navigate('/selectwallboard/pending');
      }, 2000);
    }
  };

  const sharedPDF = async PDF => {
    const height = Template_heights[data.parent_template_name] || '1150px';
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('pdf', PDF, 'generated.pdf');
      formData.append('width', '291');
      formData.append('height', height);

      const result = await Axios.Filepost(API.Change_PDF_Dimenssions, formData, {
        responseType: 'blob', // This tells Axios to handle the response as binary data
      });
      if (result.status === 200) {
        //  console.log(result.data)
        const response = await axios.get(result.data.outputUrl, {
          responseType: 'blob', // This tells Axios to handle the response as binary data
        });
        // Create a URL for the Blob and trigger a download
        if (excludedTemplates.includes(data.parent_template_name)) {
          return;
        } else {
          const blobUrl = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${data.wallboard_name}.pdf`; // Set the desired file name
          document.body.appendChild(link); // Append link to body
          link.click(); // Trigger the download
          link.remove(); // Remove the link after downloading

          // Revoke the object URL to free up memory
          URL.revokeObjectURL(blobUrl);
          return response.data;
        }
      }
    } catch (error) {
      console.error(error, 'Error While getting PDF');
      setIsLoading(false);
    }
  };

  const ApprovalStatus = async (approval_status, message) => {
    try {
      if (edit.approveDisabled === true) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please save the wallboard before proceeding.',
        });
      } else {
        setApproved(true);
        const id = isformEditted.id || params.id;
        await PreventICYMI2AD(1000);
        const pdfBlob = approval_status === 2 ? null : await generatePDF();
        const outputPDFfile = pdfBlob ? await sharedPDF(pdfBlob) : null;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('approvalStatus', approval_status ? approval_status.toString() : '');
        // formData.append("template_theme_name", data.color);
        if (message !== undefined) {
          formData.append('reason', message);
        }
        if (approval_status !== 2) {
          formData.append(
            'pdfFile',
            templatesToUsePdfBlob.includes(data.parent_template_name) ? pdfBlob : outputPDFfile,
            'generated.pdf'
          );
        }

        const result = await Axios.Filepost(API.Approve_and_Disapprove_Wallboard, formData);
        if (result.status === 200) {
          setIsLoading(false);
          setSnackbar({
            open: true,
            message: result.data.message,
            severity: 'success',
          });
          setPopupOpen(false);
          await PreventICYMI2AD(1000);
          setTimeout(() => {
            navigate('/selectwallboard/pending');
          }, 3000);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err, 'This is Error');
      setApproved(false);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'An error occurred',
        severity: 'error',
      });
      if (window.innerWidth !== window.screen.availWidth) {
        const node = document.getElementById('pdfContainer');
        node.style.border = '1px solid black';
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please adjust your screen size to 100% of the browser window.',
        });
      }
    }
  };

  const handleApprove = async () => {
    await ApprovalStatus(1);
  };

  const handleDisApprove = () => {
    if (edit.approveDisabled === true) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Please save the wallboard before proceeding.',
      });
    } else {
      setPopupOpen(true);
    }
  };

  // Wallboard Update API
  const handleSubmit = async e => {
    e.preventDefault();
    setEdit({ ...edit, approveDisabled: false });
    const columnUpdates = [
      {
        columnId: 'c5',
        updatedData: {
          author_img: data?.c5?.author_img,
          author_name: value.author_name,
          title: value.title,
          body: value.body,
        },
      },
    ];

    if (data.parent_template_name !== '1-COL-ICYMI-2-ADS') {
      columnUpdates.push({
        columnId: 'c6',
        updatedData: {
          author_img: data?.c6?.author_img,
          author_name: value.author_nameC6,
          title: value.titleC6,
          body: value.bodyC6,
        },
      });
    }

    const payload = {
      docId: params.id,
      template_theme_name: data.color,
      parent_template_name: data.parent_template_name,
      columnUpdates: columnUpdates,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Wallboard_Update, payload);
      if (response.status === 200) {
        // console.log(response.data, "hi this is response data ");
        setFormEditted({
          // id: response?.data.newVersionDetails.id,
          id: response?.data.updatedVersionDetails.id,
          status: true,
        });
        // const VersionData = response?.data.newVersionDetails.versiondetail;
        const VersionData = response?.data.updatedVersionDetails;
        setData({
          c5: VersionData?.c5,
          c6: VersionData?.c6,
          status: response?.data?.updatedVersionDetails.approval_status,
          wallboard_name: response?.data?.updatedVersionDetails?.wallboard_name,
          color: response.data.updatedVersionDetails.template_theme_code,
          parent_template_name: VersionData?.parent_template_name,
        });
        // const c5Body = VersionData?.c5.body;
        // const databody= c5Body.replace(/\s/g, '&nbsp;');
        setValue({
          body: VersionData?.c5.body,
          // body: databody,
          bodyC6: VersionData?.c6?.body,
          author_name: VersionData?.c5.author_name,
          author_nameC6: VersionData?.c6?.author_name,
          title: VersionData?.c5.title,
          titleC6: VersionData?.c6?.title,
          parent_template_name: VersionData?.parent_template_name,
        });
        setEdit({ c5: false, show: false });
        setSnackbar({
          open: true,
          severity: 'success',
          message: response.data.msg,
        });
        navigate(`/selectwallboard/view-version/${response?.data.updatedVersionDetails.id}`);
        await getData(response?.data.updatedVersionDetails.id);
        await PreventICYMI2AD(2000);
        // await delay(2000);
        // await ExpandWords();
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while Update');
      setIsLoading(false);
      if (err) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: err.response.data.error || err.message,
        });
      }
    }
  };

  // For PDF Generation
  const isWindows = /Windows/.test(navigator.userAgent);

  var scale = 10;

  const generatePDF = async () => {
    const containerWidth = pdfContainerRef.current.clientWidth;
    const containerHeight = pdfContainerRef.current.clientHeight;
    const Wallboard_Content = contentRef.current;
    const titles1 = Wallboard_Content.querySelector('#titles1');
    const titles = Wallboard_Content.querySelector('#titles');
    const node = document.getElementById('pdfContainer');

    // Inject style rules into a style tag
    const injectStyles = () => {
      const styleSheet = document.createElement('style');
      styleSheet.type = 'text/css';

      // Extract and include necessary styles
      const styles = [];

      if (titles1 && titles) {
        const titles1P = titles1.querySelector('p');
        if (titles1P) {
          const computedStyle = window.getComputedStyle(titles1P);
          const properties = ['font-size', 'word-spacing', 'letter-spacing', 'line-height'];

          properties.forEach(property => {
            const value = computedStyle.getPropertyValue(property);
            styles.push(`p { ${property}: ${value}; }`);
            // console.log(`Extracted ${property}: ${value}`); // Log extracted styles
          });
        }
      }

      styleSheet.innerHTML = styles.join(' ');
      document.head.appendChild(styleSheet);

      return styleSheet;
    };

    // Ensure styles are injected before proceeding
    const styleSheet = injectStyles();

    // Force reflow to ensure styles are applied
    void node.offsetHeight;

    // Function to remove the injected style sheet after processing
    const removeStyles = styleSheet => {
      document.head.removeChild(styleSheet);
    };

    console.log(node, 'Styles');
    console.clear();
    try {
      const Title = document.getElementById('editorTitle2');
      node.style.border = '1px solid transparent';
      if (isWindows === true) {
        if (Title) {
          Title.style.marginTop =
            data.parent_template_name === '1-ICYMI-POLL'
              ? '25px'
              : data.parent_template_name === '1-ICYMI-CARTOON'
                ? '25px'
                : data.parent_template_name === '1-ICYMI-2AD-2A'
                  ? '25px'
                  : '27px';
        }
        node.style.height = getHeightByTemplateName(data);
      }
      const isEdge = /Edg\//.test(navigator.userAgent);
      const isFirefox = /Firefox/.test(navigator.userAgent);
      let zoom = Math.round(window.devicePixelRatio * 100);
      const FullScreenCheck =
        isEdge === true
          ? zoom >= 100
          : isFirefox === true
            ? window.devicePixelRatio >= 1
            : window.devicePixelRatio >= 1;

      if (FullScreenCheck === true) {
        // await delay(1000);
        // await ExpandWords();
        await PreventICYMI2AD(1000);
        // console.log("Hi this has been added")
        const dataUrl = await domToImage.toPng(node, {
          allowTaint: true,
          useCORS: true,
          scale: 2,
          width: node.clientWidth * scale,
          height: node.clientHeight * scale,
          style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left',
          },
        });
        // Convert base64 to ArrayBuffer
        const byteCharacters = atob(dataUrl.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Return the Blob instead of the data URL
        const MyDocument = () => (
          <Document>
            <Page size={{ width: containerWidth, height: containerHeight }}>
              <Image src={dataUrl} style={{ width: containerWidth, height: containerHeight }} />
            </Page>
          </Document>
        );

        const blob1 = await pdf(<MyDocument />).toBlob(); // Creating the blob for a pdf file
        if (templates.includes(data.parent_template_name)) {
          saveAs(blob1, `${data.wallboard_name}.pdf`); // Automatically Saving or Downloading the file
        }
        node.style.border = '1px solid black';
        if (isWindows === true) {
          node.style.height = getOriginalHeightByTemplateName(data);
          if (
            data.parent_template_name !== '1-ICYMI-2AD-1A' &&
            data.parent_template_name !== '1-COL-ICYMI-2-ADS'
          ) {
            Title.style.marginTop = '6px';
          }
        }
        await PreventICYMI2AD(1000);
        // Remove injected style sheet
        removeStyles(styleSheet);
        return blob1;
      }
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const handleDialogYes = async () => {
    setValue(prevalue);
    setEdit({ show: false, approveDisabled: false });
    setEditorButtons({ ...EditorButtons, ShowMenu: false, CloseDialog: false });
    await PreventICYMI2AD(1000);

    // await delay(1000); // Wait for 1 second
    // await ExpandWords();
  };

  const delay = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms);
    });

  const CalculateRemainingHeight = async () => {
    const Full_Height = pdfContainerRef.current.clientHeight;
    const ContentHeight = contentRef?.current?.clientHeight;
    const result = Full_Height - ContentHeight;
    return result;
  };

  const applyStyles = (element, fontSize, wordSpacing, letterSpacing, lineHeight) => {
    element.style.setProperty('font-size', `${fontSize}px`, 'important');
    element.style.setProperty('word-spacing', `${wordSpacing}px`, 'important');
    element.style.setProperty('letter-spacing', `${letterSpacing}px`, 'important');
    element.style.setProperty('line-height', `${lineHeight}`, 'important');
  };

  const ExpandWords = async () => {
    let ContentHeight = contentRef?.current?.clientHeight;
    const WallboardHeight = getContentExpand(data);
    if (ContentHeight >= WallboardHeight) {
      return;
    } else {
      requestAnimationFrame(() => {
        const result = document.querySelector('#titles p');
        const result1 = document.querySelector('#titles1 p');
        const Checkdata =
          data.parent_template_name === '1-ICYMI-2AD-1A' ||
          data.parent_template_name === '1-COL-ICYMI-2-ADS'
            ? result
            : data.parent_template_name === '1-ICYMI-CARTOON' ||
                data.parent_template_name === '1-COL-ICYMI-CART' ||
                data.parent_template_name === '1-ICYMI-POLL' ||
                data.parent_template_name === '1-COL-ICYMI-POLL' ||
                data.parent_template_name === '1-ICYMI-POLL-NO-COL1-QR' ||
                data.parent_template_name === '1-ICYMI-POLL-NO-COL3-QR'
              ? result && result1
              : null;
        if (Checkdata) {
          let fontSize = 14.5; // Initial font size
          let wordSpacing = 0; // Initial word spacing
          let letterSpacing = -0.5; // Initial letter spacing
          let lineHeight = 1.2; // Initial line height

          while (ContentHeight < WallboardHeight) {
            // Increment the font size, word spacing, letter spacing, and line height
            fontSize += 0.0002; // Smaller increments
            wordSpacing += 0.1; // Smaller increments
            letterSpacing += 0.01; // Smaller increments
            lineHeight += 0.0002; // Smaller increments

            // Apply the new styles to both elements
            applyStyles(result, fontSize, wordSpacing, letterSpacing, lineHeight);
            if (
              data.parent_template_name !== '1-ICYMI-2AD-1A' &&
              data.parent_template_name !== '1-COL-ICYMI-2-ADS'
            ) {
              applyStyles(result1, fontSize, wordSpacing, letterSpacing, lineHeight);
            }
            // Update the content height
            ContentHeight = contentRef?.current?.clientHeight;

            // Exit if the content height exceeds 1077
            if (ContentHeight >= WallboardHeight) {
              break;
            }
          }
        }
      });
    }
  };

  const FetchAllData = async () => {
    const data = await getData();
    //  console.log(data, 'hello I am the data')
    setIsLoading1(true);
    await delay(1000); // wait for 3 seconds
    await CalculateRemainingHeight();
    // setIsLoading1(false)
    const ContentHeight = contentRef?.current?.clientHeight;
    const WallboardHeight = getContentHeight(data);
    if (ContentHeight >= WallboardHeight) {
      setIsLoading1(false);
      return;
    } else {
      // console.log('hello I am in')
      setIsLoading1(false);
      setIsDataLoaded(true);
    }
  };

  const PreventICYMI2AD = async time => {
    if (data.parent_template_name === '1-ICYMI-2AD-2A') {
      console.clear();
    } else {
      await delay(time); // Wait for 1 second
      await ExpandWords();
    }
  };

  const Wallboard_Approval_Status = async (approval_status, ScreenType) => {
    const message = 'Disapprove';
    try {
      const id = params.id;
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('approvalStatus', approval_status.toString());
      // formData.append("template_theme_name", data.color);
      if (approval_status == 2) {
        formData.append('reason', message);
      }
      const result = await Axios.Filepost(API.Approve_and_Disapprove_Wallboard, formData);
      if (result.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: result.data.message,
          severity: 'success',
        });
        setTimeout(() => {
          navigate(
            ScreenType === 'Reject'
              ? '/selectwallboard/rejected'
              : ScreenType === 'Pending' && '/selectwallboard/pending'
          );
        }, 3000);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err, 'This is Error');
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'An error occurred',
        severity: 'error',
      });
    }
  };

  const handlePending = async () => {
    await Wallboard_Approval_Status(0, 'Pending');
  };

  useEffect(() => {
    FetchAllData();
    const quillEditor = window.Quill && window.Quill.find('.editor-input.descript.task');
    if (quillEditor) {
      quillEditor.on('text-change', (delta, oldDelta, source) => {
        if (source === 'user') {
          const value1 = quillEditor.getText();
          const databody = value1.replace(/\s/g, `&nbsp;`);
          const hyphen = databody.replace(/-/g, '\u2011');
          // Replace question marks only if they are not followed by a space
          const updatedValue = hyphen.replace(/\?(?!\s)/g, '\u003F ');
          quillEditor.setText(updatedValue);

          // Move the cursor to the end of the editor
          quillEditor.setSelection(quillEditor.getLength());
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      delay(1000).then(() => {
        ExpandWords();
      });
    }
  }, [isDataLoaded]);

  const apiKey = Keys.EditorKey;

  return (
    <MainCard
      className="showVersions"
      title={data?.wallboard_name}
      {...(Details.role !== '3' &&
        (data?.status === 0 || data?.status === 2 || location?.state?.reject === 'yes') && {
          // ...(Details.role !== "3" && location?.state?.reject !== 'yes' && {
          // }),
          approve: handleApprove,
          buttontitle1: 'Approve',
          approveColor: '#00C853',
          disapprove: data?.status === 2 ? handlePending : handleDisApprove,
          buttontitle2: data?.status === 2 ? 'Pending' : 'Reject',
          disapprovecolor: data?.status === 2 ? '#15223f' : '#F44336',
        })}
    >
      <Popup
        style={{ height: '600px' }}
        open={popupOpen}
        // onClose={handlePopupClose}
        title="Please enter the reason for rejection"
        content={
          <>
            <Formik
              initialValues={{
                message: '',
              }}
              validationSchema={Yup.object().shape({
                message: Yup.string().required('Message is required'),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  if (values.message !== null || undefined) {
                    ApprovalStatus(2, values.message);
                  }
                  if (scriptedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);
                  if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  className={classes.forms}
                  noValidate
                  onSubmit={handleSubmit}
                  {...others}
                  autoComplete="off"
                >
                  {/* Email */}
                  <FormControl
                    fullWidth
                    error={Boolean(touched.message && errors.message)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-message">Reason</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="text"
                      value={values.message}
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Reason"
                      inputProps={{}}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Box sx={{ mt: 1 }} style={{ textAlign: 'center' }}>
                    {/* <AnimateButton> */}
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      style={{ background: '#F44336', marginRight: 10 }}
                      onClick={() => setPopupOpen(false)}
                    >
                      Close
                    </Button>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{ background: '#15223F' }}
                    >
                      Reject
                    </Button>
                    {/* </AnimateButton> */}
                  </Box>
                </form>
              )}
            </Formik>
          </>
        }
      />
      <>
        <div className="main-header none">
          <div className="main-title">
            <div className="title">
              <h3 className={classes.h3}>PHYSICIAN WEEKLY</h3>
              <h2>
                <span>L</span>IVER <span>C</span>ANCER
              </h2>
            </div>
            <div className="side-bt-content">
              <p className={classes.p} style={{ margin: 0, fontWeight: 700, fontSize: 17 }}>
                June 2023
              </p>
              <p className={classes.p} style={{ margin: 0, fontWeight: 700, fontSize: 17 }}>
                Vol. IV • Issue No. 6
              </p>
            </div>
          </div>
          <div className="side-info">
            <p
              className={classes.p}
              style={{
                backgroundImage: 'url(top-back.png)',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                padding: 15,
                color: '#22553f',
                textAlign: 'left',
                paddingLeft: '4em',
                paddingTop: 40,
                fontFamily: 'Korolev',
              }}
            >
              <span style={{ fontWeight: 700, fontSize: 22 }}>
                PHYSICIAN’S WEEKLY EDITORIAL BOARD:
              </span>
              <br />
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                Dr. Linda Girgis (Editor-in-Chief),
                <br />
                Dr. Jasminka Criley, Dr. Umbereen Nehal,
                <br />
                Dr. Alex McDonald
              </span>
            </p>
            <p
              className={classes.p}
              style={{
                textAlign: 'left',
                paddingLeft: '1.5em',
                margin: 0,
                paddingBottom: 18,
              }}
            >
              <a
                href="www.physiciansweekly.com"
                style={{
                  color: '#fff',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontFamily: '"Korolev"',
                  fontSize: 22,
                }}
              >
                www.physiciansweekly.com
              </a>
            </p>
          </div>
        </div>
        <div className="main-section">
          <div className="col-1 none">
            <img className={classes.img} src="From-the-Editor.png" alt="" />
            <div className="col_1_content secton_cont">
              <h2>How the Concept of Resiliency Training in Medicine Is a Fallacy</h2>
              <p className={`${classes.p} auth-img`}>
                <img className={classes.img} src="doc-3.jpg" alt="" />
                By
                <br />
                <strong>Linda Girgis, MD</strong>Physician’s Weekly Editor-in-Chief
              </p>
              <p className={`${classes.p} first-letter`}>
                It seems like resiliency training is being offered as a fix for burnout. Some
                institutions even mandate it. However, they fail to realize that our whole education
                necessitated resiliency. If you weren’t resilient, you didn’t make it. What other
                profession makes you stay in the hospital for days at a time and miss meals because
                you were taking care of a dying patient in the ED? Then, when you graduate, you are
                responsible for everything, even if someone else made a mistake. Resiliency training
                does not talk about the grueling hours or dealing with grief. Furthermore, these
                courses fail to realize that doctors are burnt out because the system is
                dysfunctional, not because we’re weak.
              </p>
              <p className={classes.p}>
                After going through all that, it would make sense that we would get paid easily,
                right? But it is often a series of jumping through hoops to get paid, and sometimes
                we are forced to write it off. These are only a few of the ways in which the system
                is failing us.
              </p>
              <h2>How can we address burnout in ourselves?</h2>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Recognize it for what it is.</strong>
                You’re not drained because you’re weak. You’re drained because you went through
                years of training and the healthcare system does not enable you to practice
                effectively.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Learn to say no.</strong>
                Every committee wants doctors on it. Is your presence there really going to change
                anything or is it just a time drain? › Take sick days and vacation time. We need to
                take better care of ourselves. No one else is going to.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>
                  › Find enjoyment outside of our careers.
                </strong>
                I was a doctor for years before I could say what I like to do for fun. We all need
                something that takes our minds off medicine.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Stop taking work home.</strong>
                As a doctor in private practice, I struggle with this, but I do know the days I
                leave it all at the office are much more relaxed.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Stop taking work home.</strong>
                As a doctor in private practice, I struggle with this, but I do know the days I
                leave it all at the office are much more relaxed.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Consider career options.</strong>
                In medicine, there are many practice models and career options. It’s worth knowing
                what’s available.
              </p>
              <p className={classes.p}>
                <strong style={{ color: '#001b49' }}>› Run for office.</strong>
                Politicians make many decisions regarding healthcare. We need more doctors in this
                arena who understand the issues. Until someone addresses the systematic dysfunction,
                we must continue to push back, but that is exhausting. We need to start taking
                better care of ourselves. ◗ PW
              </p>
              <p className={classes.p}>
                ›<strong style={{ color: '#001b49' }}> Run for office.</strong>
                Politicians make many decisions regarding healthcare. We need more doctors in this
                arena who understand the issues. Until someone addresses the systematic dysfunction,
                we must continue to push back, but that is exhausting. We need to start taking
                better care of ourselves. ◗ PW
              </p>
              <div className="bar-code">
                <div className="bar-content">
                  <p className={`${classes.p} footer-scan`}>
                    Scan the QR code to hear more from Dr. Girgis and all our Doctor’s Voice
                    bloggers!
                  </p>
                </div>
                <div className="barcode_img">
                  <img className={classes.img} src="02.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 none">
            <div className="col_2_data">
              <div>
                <div className="individual_section">
                  <div className="ind_mid_content">
                    <h3 className={classes.h3}>
                      Individualize
                      <br />
                      Hepatocellular
                      <br />
                      Carcinoma
                      <br />
                      Treatment
                      <br />
                      With a<br />
                      Multidisciplinary
                      <br />
                      Team
                    </h3>
                  </div>
                </div>
              </div>
              <div className="mid-cols">
                <div className="col_2_1">
                  <div className="col_2_1_content">
                    <p className={`${classes.p} auth-img`}>
                      <img className={classes.img} src="doc-1.jpg" alt="" />
                      <span>
                        Contributor
                        <br />
                        <strong>Nadine AbiJaoudeh, MD, FSIR, CCRP</strong>
                        <span>
                          Professor of Radiology Chief of Interventional Radiology Director of
                          Clinical Research Chair of Diversity Committee Department of Radiological
                          Sciences University of California Irvine
                        </span>
                      </span>
                    </p>
                    <p className={`${classes.p} first-letter`}>
                      The latest guidelines from multiple professional societies—including NCCN,
                      ASCO, the Barcelona Clinic, and the American Association for the Study of
                      Liver Diseases— recommend strongly that patients with hepatocellular carcinoma
                      (HCC) be evaluated by a multidisciplinary team. “When you have a hammer,
                      everything is a nail,” explains Nadine Abi-Jaoudeh, MD, FSIR, CCRP. “The
                      multidisciplinary approach allows patients access to all the specialists who
                      might be involved in the management of HCC, therefore optimizing their chances
                      to get the best treatment. This has been shown in published studies.” Dr.
                      Abi-Jaoudeh notes that tumor board meetings—when treatment plans for new and
                      complex cancer cases are discussed by multidisciplinary teams to decide, as a
                      group, on the best treatment plans for a patient—allow for previously
                      unconsidered solutions to become apparent.
                    </p>
                    <h2>Gathering Input from Every Specialty</h2>
                    <p className={classes.p}>
                      "The interactions of multidisciplinary
                      <br />
                      teams vary,” she adds. “However,
                      <br />
                      the best multidisciplinary interac-
                      <br />
                      -tions consist of input from every
                      <br />
                      potentially involved specialty.
                      <br />
                      Medical knowledge is growing at
                      <br />
                      an exponential level, and it is not
                      <br />
                      possible to stay up to date
                      <br />
                      on new developments in
                      <br />
                      other specialty literature
                      <br />
                      to the extent that the
                      <br />
                      specialists themselves know it.
                      <br />
                      This is why having those
                      <br />
                      interactions and discus
                    </p>
                  </div>
                </div>
                <div className="col_2_2">
                  <div className="col_2__2_content">
                    <p className={classes.p}>
                      sions is so important. It is a teaching and learning experience as we find out
                      about the latest studies from each other’s specialties. It is also a way to
                      narrow down gaps in the literature.” As an interventional radiologist, Dr.
                      AbiJaoudeh notes that “radiology’s review of the imaging can result in changes
                      in management, because they can point to a subtle finding that would change
                      operative risk or disease features that can change risk assessment. For
                      example, an HCC can be well circumscribed or infiltrative, and for the same
                      size lesion, infiltrative —which is not always described in the report —is
                      more aggressive.” The multidisciplinary team of which Dr. Abi-Jaoudeh is a
                      member communicates well, she says. The collegial environment includes a
                      shared knowledge that all members have a role and that both patients and
                      clinicians will be treated with respect. “The main goal is to provide the
                      patient with the best outcome,” she adds. “We are patient-centric, and things
                      fall into place as a result.”
                    </p>
                    <h3 className={classes.h3}>The Importance of Individualized Care</h3>
                    <p className={classes.p}>
                      he role of the multidisciplinary team is of great importance in treating
                      patients with advanced HCC due to the particular importance of individualizing
                      care for this patient population. “There are specific characteristics unique
                      to individuals with advanced HCC that make them better suited for one therapy
                      versus
                      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; another,” says Dr. Abi-Jaoudeh. “
                      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HCC staging is very broad.
                      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intermediate and advanced stages <br />{' '}
                      &nbsp;&nbsp;&nbsp; can be divided into multiple categories
                      &nbsp;&nbsp;&nbsp;and several clinical factors
                      <br /> &nbsp;&nbsp;&nbsp; come into &nbsp;&nbsp;play. Two patients
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;can be
                      classified as intermediate
                      <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; stage
                      but have different
                      <br />{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;disease
                      burdens. Also, one <br />{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;patient
                      mayhave underlying
                      <br />{' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;portal
                    </p>
                  </div>
                </div>
                <div className="col_2_3">
                  <div className="key-takeys">
                    <img className={classes.img} src="key.png" />
                    <h4>KEY TAKEAWAYS</h4>
                    <div className="space-1">
                      <p className={`${classes.p} numbers`}>1</p>
                      <p className={`${classes.p} side-num-cont`}>
                        Subtle changes in hepatocellular carcinoma (HCC) can influence operative
                        risk or disease features, which can change risk assessment.{' '}
                      </p>
                    </div>
                    <div className="space-1">
                      <p className={`${classes.p} numbers`}>2</p>
                      <p className={`${classes.p} side-num-cont`}>
                        The multidisciplinary team enables attention to these subtle changes, which
                        can result in more individualized care for this patient population.{' '}
                      </p>
                    </div>
                    <div className="space-1">
                      <p className={`${classes.p} numbers`}>3</p>
                      <p className={`${classes.p} side-num-cont`}>
                        To further individualize care, clinicians can utilize tools such as the
                        ChildPugh score and alpha-fetoprotein tumor scores.{' '}
                      </p>
                    </div>
                  </div>
                  <p className={classes.p}>
                    hypertension, or varices that are prone to bleeding and not easily banded. This
                    is why assessing each patient individually and discussing cases at tumor boards
                    are essential.” It is these discussions at tumor board meetings that help ensure
                    care is individualized as opposed to being based on more “black and white” rules
                    that may leave some patients without care that they could have benefited from,
                    Dr. Abi-Jaoudeh explains. “This is why patients who are not discussed at tumor
                    boards have lower survival.” To further ensure individualized care, Dr.
                    Abi-Jaoudeh utilizes tools that include the Child-Pugh score. She notes that
                    patients with a very high score (ie, 8 or 9) are sometimes placed on
                    immunotherapy alone, whereas those with better functional status and a good
                    Child-Pugh score are often placed on trials, if eligible, or a combination of
                    locoregional and systemic therapies. “The tumor board and I follow the Barcelona
                    Clinic Liver Cancer guidelines and use Child-Pugh and alpha-fetoprotein tumor
                    scores to guide decisions,” Dr. Abi-Jaoudeh says. ◗ PW
                  </p>
                </div>
                <div className="foter-img">
                  <div className="girl-img">
                    <img className={classes.img} src="1149568_202306-34.PNG" />
                  </div>
                  <img className={classes.img} src="footer-bar-code-img.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 none">
            <img className={classes.img} src="1149568_202306-3.png" alt="" />
            <div className="col_3_content col-3_section">
              <h2>What Physicians Should Do When They Make a Medication Error</h2>
              <p className={[classes.p, 'first-letter']}>
                Physicians, like all people, are not immune to error. Medical errors can occur at
                any point during patient care; one such error would be prescribing the wrong
                chemotherapy dose for a patient with liver cancer. A StatPearls article noted that
                between 7,000 and 9,000 people in the United States die annually due to medication
                errors, with cumulative costs of more than $40 billion. These errors impact patient
                health and impose psychological and physical costs. Victims of medical error may
                develop a sense of mistrust with their physicians and the healthcare system in
                general. Given the wide range of medications available, prescription errors are
                common. The StatPearls article notes that prescription errors account for nearly
                half of all patient injuries related to medication errors, from prescribing the
                incorrect medication to the incorrect dose
              </p>
              <h2>Addressing Error-Prone Areas</h2>
              <p className={classes.p}>
                The medical field is characterized by an unfortunate culture of blame in which
                medical errors often lead to fines, licensure risks, and litigation, as well as
                shaming. Nonetheless, physicians have an ethical duty to immediately report medical
                errors. The Association of Managed Care Pharmacy asserts that physicians should not
                have to feel uneasy about either committing or reporting an error for fear of
                subjection to punitive action, suggesting that the healthcare community should
                rather concentrate on addressing error-prone areas of medication prescription and
                use. In other words, the healthcare community should ask why certain errors seem
                prone to recur and how the system can be changed to prevent future occurrences.
              </p>
              <p className={classes.p}>
                Nonetheless, the Association of American Medical Colleges (AAMC) suggests physicians
                provide complete disclosure after a medication error, including a discussion with
                the patient/ family, timely reporting, and an evaluation of any consequences. The
                AAMC notes that any clinicians involved in the error receive emotional support.
                According to the AAMC, the aforementioned measures can spare hospitals, patients,
                and families years of arduous litigation
              </p>
              <p className={classes.p}>
                Naveed Saleh, MD, MS, says it would behoove physicians to adopt strategies that
                prevent errors entirely. First and foremost, physicians should tackle every
                prescription with the utmost care. Other recommendations include avoiding misreads
                and misinterpretations by employing electronic prescriptions and not using
                abbreviations, to sidestep handwriting legibility issues. Dr. Saleh also suggests
                noting precise dosages, specifying treatment duration, and supplying clear
                instructions.◗ PW
              </p>
              <div className="bar-code">
                <div className="bar-content">
                  <p className={[classes.p, 'footer-scan']}>
                    Scan the QR code for additional Business of Medicine articles on medical claim
                    denials, malpractice premiums, and more!
                  </p>
                </div>
                <div className="barcode_img">
                  <img className={classes.img} src="05.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 ">
            <img
              className={[classes.img, 'col-4-img']}
              src="1149568_202306_Page_1_Image_0013.jpg"
              alt=""
            />
            {data?.status === 0 && !edit.show && Details.role !== '3' && !approved && (
              <div style={{ textAlign: 'center' }}>
                <Tooltip title="Edit" arrow placement="top">
                  <Button
                    size="small"
                    variant="contained"
                    style={{
                      background: '#15223F',
                      padding: '6px 0px 6.1px 10px',
                      minWidth: '0px',
                      marginBottom: '10px',
                    }}
                    startIcon={<FaRegEdit style={{ fontSize: 15 }} />}
                    onClick={async () => {
                      //  await ExpandWords();
                      setEdit({ show: true, approveDisabled: true });
                      await PreventICYMI2AD(1000);
                      // await delay(1000); // Wait for 1 second
                      // await ExpandWords();
                    }}
                  />
                </Tooltip>
              </div>
            )}

            {edit.show && !isLoading && (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Tooltip title="Close" arrow placement="top">
                    <Button
                      size="large"
                      variant="contained"
                      style={{
                        background: '#C62828',
                        fontSize: '20px',
                        padding: '5px',
                        minWidth: '0px',
                        marginBottom: '7.1px',
                      }}
                      onClick={async () => {
                        // setValue(prevalue);
                        // setEdit({ show: false, approveDisabled: false });
                        // await delay(1000); // Wait for 1 second
                        // await ExpandWords();
                        setEditorButtons({
                          ...EditorButtons,
                          CloseDialog: true,
                        });
                      }}
                    >
                      <AiOutlineClose />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save" arrow placement="top">
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{
                        background: '#1d213e',
                        fontSize: '20px',
                        padding: '5px',
                        minWidth: '0px',
                        marginBottom: '7.1px',
                      }}
                    >
                      <BiSolidSave />
                    </Button>
                  </Tooltip>
                </div>

                <div
                  style={{
                    // backgroundColor: "#e9e9ea",
                    backgroundColor:
                      data.color === '#e0a600'
                        ? '#fbf4e8'
                        : data.color === '#a0251c'
                          ? '#f6eae3'
                          : data.color === '#22553f'
                            ? '#e9e9ea'
                            : '',
                    padding: '12px',
                    height: getOriginalHeightByTemplateName(data),
                  }}
                  className="ContentEditor"
                  ref={pdfContainerRef}
                >
                  {/* <p className="column_data">
                    Please Enter the Data of{" "}
                    <span style={{ color: "#f44336" }}>C5</span>
                  </p> */}
                  <div ref={contentRef}>
                    <div className="editor">
                      <Editor
                        apiKey={apiKey}
                        id="editorTitle1"
                        value={value.title}
                        init={{
                          menubar: true,
                          inline: true,
                          // content_style: `color: ${data.color}`
                          content_style: `#editorTitle1 { color: ${
                            data && data.color
                          }; margin-bottom:0px; }`, // Add a custom class and its style
                          font_family_formats: getFontStyles(
                            'Adobe Garamond Pro=AGaramond-Regular;',
                            'Korolev Condensed Bold=korolev-condensed;'
                          ),
                          plugins: 'fontfamily',
                        }}
                        onEditorChange={(content, editor) => {
                          setValue({ ...value, title: content });
                        }}
                        style={{ color: data.color }}
                      />
                      {/* <ReactQuill
                      theme="bubble"
                      name="title"
                      value={value.title}
                      // value={`<h3>${value.title}</h3>`} // Wrap value.title in an "h3" tag
                      onChange={(newValue) => {
                        setValue({ ...value, title: newValue });
                      }}
                      formats={{ header: 3 }}
                      className="editor-input "
                      modules={modules}
                      placeholder="Please Enter Title *"
                      style={{ color: data.color }}
                    /> */}
                      <p
                        className={[
                          // classes.p,
                          'auth-img hello',
                        ]}
                        style={{
                          display:
                            !data.c5.author_img ||
                            !data?.c5?.dimensions ||
                            data.c5.dimensions.width !== 269 ||
                            data.c5.dimensions.height !== 270 ||
                            !data.c5.author_name
                              ? 'none'
                              : 'block',
                          // pointerEvents: "none",
                          borderColor:
                            data.color === '#e0a600'
                              ? '#e0a600'
                              : data.color === '#a0251c'
                                ? '#a0251c'
                                : '',
                          margin: isWindows ? '5px 13px 5px 0' : '7px 13px 5px 0',
                        }}
                      >
                        {/* <span style={{ width: "74px", height: "74px", display: "block"}}> */}
                        <span
                          style={{
                            width: '69px',
                            height: '70px',
                            display: 'block',
                          }}
                        >
                          <img
                            // height="67px"
                            className={classes.img}
                            // src={data?.c5?.author_img}
                            src={`${data?.c5?.author_img}`}
                            alt=""
                          />
                        </span>
                        <span
                          style={{
                            color:
                              data.color === '#e0a600'
                                ? '#e0a600'
                                : data.color === '#a0251c'
                                  ? '#a0251c'
                                  : '',
                          }}
                        >
                          <Editor
                            apiKey={apiKey}
                            id="authorName"
                            value={value.author_name}
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#authorName p {font-size: 12px !important;font-family:'korolev-condensed';color: ${
                                data && data.color
                              }; }`,
                              font_family_formats: getFontStyles(
                                'Adobe Garamond Pro=AGaramond-Regular;',
                                'Korolev Condensed Bold=korolev-condensed;'
                              ),
                              plugins: 'fontfamily',
                              // content_style: `#authorName { color: ${
                              //   data && data.color === "#0079C2"
                              //     ? "#001948"
                              //     : data.color
                              // }; margin-bottom:0px; }`, // Add a custom class and its style
                            }}
                            onEditorChange={async (content, editor) => {
                              setValue({ ...value, author_name: content });
                              await PreventICYMI2AD(500);
                              // await delay(500);
                              // await ExpandWords();
                            }}
                            // style={{ color: data.color }}
                          />
                          {/* <strong>{data?.c5?.author_name}</strong> */}
                        </span>
                      </p>

                      {/* <ReactQuill
                      theme="bubble"
                      name="body"
                      id="chat2"
                      value={value.body}
                      onChange={(newValue) => {
                        const value1 = newValue;
                        const updatedValue = value1
                          .replace(/\s/g, `&nbsp;`)
                          .replace(/-/g, "\u2011");
                        setValue({ ...value, body: updatedValue });
                      }}
                      className="editor-input descript  task"
                      modules={modules}
                      placeholder="Please Enter Description *"
                    /> */}
                      <Editor
                        apiKey={apiKey}
                        id="titles"
                        className="editor-input descript  task"
                        // className="editorss"
                        value={value.body}
                        init={{
                          menubar: true,
                          inline: true,
                          font_family_formats: fontStyles,
                          plugins: 'fontfamily', // Ensure fontfamily plugin is enabled
                          // content_css: ['../../../../../fonts/AGaramondPro-Regular.otf'], //Content CSS is used for Giving a New Font
                        }}
                        onEditorChange={async (content, editor) => {
                          setValue({ ...value, body: content });
                          await PreventICYMI2AD(500);
                          // await delay(500);
                          // await ExpandWords();
                        }}
                      />
                      {data.parent_template_name === '1-ICYMI-2AD-1A' ||
                        (data.parent_template_name === '1-COL-ICYMI-2-ADS' && (
                          <span
                            style={{
                              width: '15px',
                              height: '15px',
                              display: 'inline-block',
                            }}
                          >
                            {data && (
                              <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                            )}
                          </span>
                        ))}
                      {/* <span
                      style={{
                        width: "15px",
                        height: "15px",
                        display: "inline-block",
                      }}
                    >
                      {data && (
                        <img
                          src={images.PW_FAV_ICON}
                          style={{ width: 15, height: 15 }}
                        />
                      )}
                    </span> */}
                    </div>

                    {/* <p className="column_data">
                    Please Enter the Data of{" "}
                    <span style={{ color: "#f44336" }}>C6</span>
                  </p> */}
                    <div className="editor">
                      {/* {data.c6.author_img !== "" && (
                      <TextField
                        id="outlined-required"
                        label="Author Name"
                        fullWidth
                        className="authorName"
                        value={value.author_nameC6}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            author_nameC6: e.target.value,
                          });
                        }}
                        // error={}
                        // helperText={}
                      />
                    )} */}
                      {/* <ReactQuill
                      theme="bubble"
                      name="title"
                      value={value.titleC6}
                      onChange={(newValue) => {
                        setValue({ ...value, titleC6: newValue });
                      }}
                      className="editor-input"
                      modules={modules}
                      placeholder="Please Enter Title *"
                      style={{ color: data.color, margin: "5px 0" }}

                      // style={{ color: "#22553f" }}
                    /> */}
                      {data.parent_template_name !== '1-ICYMI-2AD-1A' &&
                        data.parent_template_name !== '1-COL-ICYMI-2-ADS' && (
                          <>
                            <Editor
                              apiKey={apiKey}
                              id="editorTitle2"
                              value={value.titleC6}
                              init={{
                                menubar: true,
                                inline: true,
                                // content_style: `color: ${data.color}`
                                content_style: `#editorTitle2 { color: ${
                                  data && data.color
                                }; margin-bottom:0px; margin-top:6px}`,
                                font_family_formats: getFontStyles(
                                  'Adobe Garamond Pro=AGaramond-Regular;',
                                  'Korolev Condensed Bold=korolev-condensed;'
                                ),
                                plugins: 'fontfamily',
                              }}
                              onEditorChange={(content, editor) => {
                                setValue({ ...value, titleC6: content });
                              }}
                              style={{ color: data.color }}
                            />
                            {/* <ReactQuill
                      id="chat1"
                      theme="bubble"
                      name="body"
                      value={value.bodyC6}
                      onChange={(newValue) => {
                        const value1 = newValue;
                        const updatedValue = value1
                          .replace(/\s/g, `&nbsp;`)
                          .replace(/-/g, "\u2011");
                        setValue({ ...value, bodyC6: updatedValue });
                      }}
                      className="editor-input"
                      modules={modules}
                      placeholder="Please Enter Description *"
                    /> */}
                            <p
                              className={[
                                // classes.p,
                                'auth-img hello',
                              ]}
                              style={{
                                display:
                                  !data.c6.author_img ||
                                  !data?.c6?.dimensions ||
                                  data.c6.dimensions.width !== 269 ||
                                  data.c6.dimensions.height !== 270 ||
                                  !data.c6.author_name
                                    ? 'none'
                                    : 'block',
                                // pointerEvents: "none",
                                borderColor:
                                  data.color === '#e0a600'
                                    ? '#e0a600'
                                    : data.color === '#a0251c'
                                      ? '#a0251c'
                                      : '',
                                margin: isWindows ? '5px 13px 5px 0' : '7px 13px 5px 0',
                              }}
                            >
                              {/* <span style={{ width: "74px", height: "74px", display: "block"}}> */}
                              <span
                                style={{
                                  width: '69px',
                                  height: '70px',
                                  display: 'block',
                                }}
                              >
                                <img
                                  // height="67px"
                                  className={classes.img}
                                  // src={data?.c5?.author_img}
                                  src={`${data?.c6?.author_img}`}
                                  alt=""
                                />
                              </span>
                              <span
                                style={{
                                  color:
                                    data.color === '#e0a600'
                                      ? '#e0a600'
                                      : data.color === '#a0251c'
                                        ? '#a0251c'
                                        : '',
                                }}
                              >
                                <Editor
                                  apiKey={apiKey}
                                  id="authorNamec6"
                                  value={value.author_nameC6}
                                  init={{
                                    menubar: true,
                                    inline: true,
                                    content_style: `#authorNamec6 p {font-size: 12px !important;font-family: 'korolev-condensed'; color: ${
                                      data && data.color
                                    };}`,
                                    font_family_formats: getFontStyles(
                                      'Adobe Garamond Pro=AGaramond-Regular;',
                                      'Korolev Condensed Bold=korolev-condensed;'
                                    ),
                                    plugins: 'fontfamily',
                                    // content_style: `#authorName { color: ${
                                    //   data && data.color === "#0079C2"
                                    //     ? "#001948"
                                    //     : data.color
                                    // }; margin-bottom:0px; }`, // Add a custom class and its style
                                  }}
                                  onEditorChange={(content, editor) => {
                                    setValue({
                                      ...value,
                                      author_nameC6: content,
                                    });
                                  }}
                                  // style={{ color: data.color }}
                                />
                                {/* <strong>{data?.c6?.author_name}</strong> */}
                              </span>
                            </p>
                            <Editor
                              apiKey={apiKey}
                              id="titles1"
                              className="editor-input descript  task"
                              // className="editorss"
                              value={value.bodyC6}
                              init={{
                                menubar: true,
                                inline: true,
                                font_family_formats: fontStyles,
                                plugins: 'fontfamily',
                                // content_style: `color: ${data.color}`
                                // content_style: `.editorss { color: ${data.color}; }` // Add a custom class and its style
                              }}
                              onEditorChange={async (content, editor) => {
                                setValue({ ...value, bodyC6: content });
                                await PreventICYMI2AD(500);

                                // await delay(500);
                                // await ExpandWords();
                              }}
                            />
                            <span
                              style={{
                                width: '15px',
                                height: '15px',
                                display: 'inline-block',
                              }}
                            >
                              {data && (
                                <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                              )}
                            </span>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </form>
            )}

            {!edit.show && !isLoading && (
              <div
                style={{
                  // backgroundColor: "#e9e9ea",
                  backgroundColor:
                    data.color === '#e0a600'
                      ? '#fbf4e8'
                      : data.color === '#a0251c'
                        ? '#f6eae3'
                        : data.color === '#22553f'
                          ? '#e9e9ea'
                          : '',
                  padding: '12px',
                  // ...styles.consistentSize,
                  height: getOriginalHeightByTemplateName(data),
                }}
                ref={pdfContainerRef}
                id="pdfContainer"

                // style={styles.consistentSize}
              >
                <div ref={contentRef}>
                  <div className="editor">
                    {/* <ReactQuill
                    theme="bubble"
                    name="title"
                    readOnly={true}
                    value={value.title}
                    onChange={(newValue) => {
                      setValue({ ...value, title: newValue });
                    }}
                    formats={{ header: 3 }}
                    className="editor-input "
                    modules={modules}
                    placeholder="Please Enter Title *"
                    style={{ color: data.color }}
                  /> */}
                    <Editor
                      apiKey={apiKey}
                      id="editorTitle1"
                      value={value.title}
                      disabled
                      init={{
                        menubar: true,
                        inline: true,
                        readonly: true,
                        // content_style: `color: ${data.color}`
                        content_style: `#editorTitle1 { color: ${
                          data && data.color
                        }; margin-bottom:0px; }`, // Add a custom class and its style
                      }}
                      onEditorChange={(content, editor) => {
                        setValue({ ...value, title: content });
                      }}
                      style={{ color: data.color }}
                    />

                    {!data.c5.author_img ||
                    !data?.c5?.dimensions ||
                    data.c5.dimensions.width !== 269 ||
                    data.c5.dimensions.height !== 270 ||
                    !data.c5.author_name ? null : (
                      <p
                        className={[
                          // classes.p,
                          'auth-img hello',
                        ]}
                        style={{
                          display:
                            !data.c5.author_img ||
                            !data?.c5?.dimensions ||
                            data.c5.dimensions.width !== 269 ||
                            data.c5.dimensions.height !== 270 ||
                            !data.c5.author_name
                              ? 'none'
                              : 'block',
                          // display: data.c5.author_img === "" && data.c5.author_name === "" && data.c5.author_name === undefined  ? "none" : "block",
                          // pointerEvents: "none",
                          borderColor:
                            data.color === '#e0a600'
                              ? '#e0a600'
                              : data.color === '#a0251c'
                                ? '#a0251c'
                                : '',
                          margin: isWindows ? '5px 13px 5px 0' : '7px 13px 5px 0',
                        }}
                      >
                        {/* <span style={{ width: "74px", height: "74px", display: "block"}}> */}
                        <span
                          style={{
                            width: '69px',
                            height: '70px',
                            display: 'block',
                          }}
                        >
                          <img
                            // height="67px"
                            className={classes.img}
                            // src={data?.c5?.author_img}
                            // src={`https://www.physiciansweekly.com/wp-content/uploads/${data?.c5?.author_img}`}
                            src={`${data?.c5?.author_img}`}
                            alt=""
                            // onLoad={handleImageLoad1}
                          />
                        </span>
                        <span
                          style={{
                            color:
                              data.color === '#e0a600'
                                ? '#e0a600'
                                : data.color === '#a0251c'
                                  ? '#a0251c'
                                  : '',
                          }}
                        >
                          <Editor
                            apiKey={apiKey}
                            id="authorName"
                            value={value.author_name}
                            disabled
                            init={{
                              menubar: true,
                              inline: true,
                              content_style: `#authorName p {font-size: 12px !important;font-family:'korolev-condensed';color: ${
                                data && data.color
                              };}`,
                              readonly: true,

                              // content_style: `#authorName { color: ${
                              //   data && data.color === "#0079C2"
                              //     ? "#001948"
                              //     : data.color
                              // }; margin-bottom:0px; }`, // Add a custom class and its style
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, author_name: content });
                            }}
                            // style={{ color: data.color }}
                          />
                          {/* <strong>{data?.c5?.author_name}</strong> */}
                        </span>
                      </p>
                    )}

                    {/* <ReactQuill
                    theme="bubble"
                    name="body"
                    id="chat2"
                    readOnly={true}
                    // value={removeHtmlTags(value.body)}
                    value={value.body}
                    // value={`<pre>${value.body}</pre>`}
                    onChange={(newValue) => {
                      const value1 = newValue;
                      const updatedValue = value1
                        .replace(/\s/g, `&nbsp;`)
                        .replace(/-/g, "\u2011");
                      setValue({ ...value, body: updatedValue });
                    }}
                    className="editor-input descript chat-gpt chat2"
                    modules={modules}
                    placeholder="Please Enter Description *"
                  /> */}
                    <Editor
                      apiKey={apiKey}
                      id="titles"
                      className="editor-input descript  task"
                      // className="editorss"
                      value={value.body}
                      init={{
                        menubar: true,
                        inline: true,
                        // content_style: `color: ${data.color}`
                        // content_style: `.editorss { color: ${data.color}; }` // Add a custom class and its style
                        readonly: true,
                      }}
                      disabled
                      onEditorChange={(content, editor) => {
                        setValue({ ...value, body: content });
                      }}
                    />
                    {data.parent_template_name === '1-ICYMI-2AD-1A' ||
                      (data.parent_template_name === '1-COL-ICYMI-2-ADS' && (
                        <span
                          style={{
                            width: '15px',
                            height: '15px',
                            display: 'inline-block',
                          }}
                        >
                          {data && (
                            <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                          )}
                        </span>
                      ))}
                    {/* <span
                    style={{
                      width: "15px",
                      height: "15px",
                      display: "inline-block",
                    }}
                  >
                    {data && (
                      <img
                        src={images.PW_FAV_ICON}
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </span> */}
                  </div>
                  <div className="editor">
                    {/* <ReactQuill
                    theme="bubble"
                    name="title"
                    value={value.titleC6}
                    onChange={(newValue) => {
                      setValue({ ...value, titleC6: newValue });
                    }}
                    className="editor-input"
                    modules={modules}
                    readOnly={true}
                    placeholder="Please Enter Title *"
                    style={{ color: data.color, margin: "5px 0" }}
                  /> */}
                    {data.parent_template_name !== '1-ICYMI-2AD-1A' &&
                      data.parent_template_name !== '1-COL-ICYMI-2-ADS' && (
                        <>
                          <Editor
                            disabled
                            apiKey={apiKey}
                            id="editorTitle2"
                            value={value?.titleC6}
                            init={{
                              menubar: true,
                              inline: true,
                              // content_style: `color: ${data.color}`
                              content_style: `#editorTitle2 { color: ${
                                data && data.color
                              }; margin-bottom:0px; margin-top:6px }`, // Add a custom class and its style
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, titleC6: content });
                            }}
                            style={{ color: data.color }}
                          />
                          {/* <ReactQuill
                    id="chat1"
                    theme="bubble"
                    name="body"
                    value={value.bodyC6}
                    readOnly={true}
                    onChange={(newValue) => {
                      const value1 = newValue;
                      const updatedValue = value1
                        .replace(/\s/g, `&nbsp;`)
                        .replace(/-/g, "\u2011");
                      setValue({ ...value, bodyC6: updatedValue });
                    }}
                    className="editor-input descript chat-gpt"
                    modules={modules}
                    placeholder="Please Enter Description *"
                  /> */}
                          {!data?.c6?.author_img ||
                          !data?.c6?.dimensions ||
                          data?.c6?.dimensions?.width !== 269 ||
                          data?.c6?.dimensions?.height !== 270 ||
                          !data?.c6?.author_name ? null : (
                            <p
                              className={[
                                // classes.p,
                                'auth-img hello',
                              ]}
                              style={{
                                display:
                                  !data?.c6?.author_img ||
                                  !data?.c6?.dimensions ||
                                  data?.c6?.dimensions?.width !== 269 ||
                                  data?.c6?.dimensions?.height !== 270 ||
                                  !data?.c6?.author_name
                                    ? 'none'
                                    : 'block',
                                // pointerEvents: "none",
                                borderColor:
                                  data.color === '#e0a600'
                                    ? '#e0a600'
                                    : data.color === '#a0251c'
                                      ? '#a0251c'
                                      : '',
                                margin: isWindows ? '5px 13px 5px 0' : '7px 13px 5px 0',
                              }}
                            >
                              {/* <span style={{ width: "74px", height: "74px", display: "block"}}> */}
                              <span
                                style={{
                                  width: '69px',
                                  height: '70px',
                                  display: 'block',
                                }}
                              >
                                <img
                                  // height="67px"
                                  className={classes.img}
                                  // src={data?.c5?.author_img}
                                  // src={`https://www.physiciansweekly.com/wp-content/uploads/${data?.c6?.author_img}`}
                                  src={`${data?.c6?.author_img}`}
                                  // onLoad={handleImageLoad}
                                  alt=""
                                />
                              </span>
                              <span
                                style={{
                                  color:
                                    data.color === '#e0a600'
                                      ? '#e0a600'
                                      : data.color === '#a0251c'
                                        ? '#a0251c'
                                        : '',
                                }}
                              >
                                <Editor
                                  apiKey={apiKey}
                                  id="authorNamec6"
                                  value={value?.author_nameC6}
                                  disabled
                                  init={{
                                    menubar: true,
                                    inline: true,
                                    content_style: `#authorNamec6 p {font-size: 12px !important;font-family: 'korolev-condensed';color: ${
                                      data && data.color
                                    };}`,
                                    readonly: true,

                                    // content_style: `#authorName { color: ${
                                    //   data && data.color === "#0079C2"
                                    //     ? "#001948"
                                    //     : data.color
                                    // }; margin-bottom:0px; }`, // Add a custom class and its style
                                  }}
                                  onEditorChange={(content, editor) => {
                                    setValue({
                                      ...value,
                                      author_nameC6: content,
                                    });
                                  }}
                                  // style={{ color: data.color }}
                                />
                                {/* <strong>{data?.c6?.author_name}</strong> */}
                              </span>
                            </p>
                          )}

                          <Editor
                            apiKey={apiKey}
                            id="titles1"
                            className="editor-input descript  task"
                            value={value?.bodyC6}
                            disabled
                            init={{
                              menubar: true,
                              inline: true,
                              readonly: true,
                              // content_style: `color: ${data.color}`
                              // content_style: `.editorss { color: ${data.color}; }` // Add a custom class and its style
                            }}
                            onEditorChange={(content, editor) => {
                              setValue({ ...value, bodyC6: content });
                            }}
                          />
                          <span
                            style={{
                              width: '15px',
                              height: '15px',
                              display: 'inline-block',
                            }}
                          >
                            {data && (
                              <img src={images.PW_FAV_ICON} style={{ width: 15, height: 15 }} />
                            )}
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="footer-img none">
          <img className={[classes.img, 'footer-bar']} src="Footer-bar.png" />
        </div>
      </>
      <Popup
        title="You have unsaved changes. Are you sure you want to proceed?"
        // padding="0"
        open={EditorButtons.CloseDialog}
        // overflowY="auto"
        // height="600px"
        // CloseHeight="500px"
        CloseOverflow="auto"
        width="400px"
        // closePopup={() =>
        //   setEditorButtons({ ...EditorButtons, CloseDialog: false })
        // }
        content={
          <>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                style={{ background: '#C62828', marginRight: 10 }}
                onClick={async () => {
                  setEditorButtons({ ...EditorButtons, CloseDialog: false });
                  await PreventICYMI2AD(1000);

                  // await delay(1000);
                  // await ExpandWords();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ background: '#15223F' }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleDialogYes}
              >
                Yes
              </Button>
            </Box>
          </>
        }
      />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <Loading isLoading={isLoading1} height={80} width={80} color="#15223F" />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
    </MainCard>
  );
};

export default ShowVersionsData;
